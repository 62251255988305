import React from 'react';
import Nav from './componentes/Nav';
import Section1 from './componentes/Section1';
import Section2 from './componentes/Section2';
import Section3 from './componentes/Section3';
import Section4 from './componentes/Section4';
import Section5 from './componentes/Section5';
import Section6 from './componentes/Section6';
import Form from './componentes/Form';
import Footer from './componentes/Footer';


function App() {
  return (
    <div>
      <Nav/>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Form />
      <Footer />
    </div>
  );
}

export default App;

