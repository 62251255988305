import React from 'react';
import '../css/section5.css';


const Section5 = () => {
    return (
        <div className="section5">
            <div className="box">
                <h3 className='text-9'>Crescimento Exponencial</h3>
                <p className='text-10'>É hora de abrir os olhos para o incrível fenômeno <br />
                 que está acontecendo no setor de tecnologia! <br />
                 Prepare-se para uma jornada de crescimento <br />
                 exponencial, inovação disruptiva e parcerias estratégicas <br />
                 que estão redefinindo os limites do possível.</p>
            </div>
            <div className="box">
                <h3 className='text-9'>Investindo no Futuro</h3>
                <p className='text-10'>Imagine estar no centro da ação, acompanhando o <br />
                nascimento de ideias que se transformam em <br />
                startups inovadoras, com potencial para se <br />
                tornarem gigantes globais. Ao investir no setor de <br />
                tecnologia, você está se tornando parte integrante <br />
                de uma revolução, com a chance de impulsionar o <br />
                futuro e moldar a maneira como vivemos amanhã.</p>
            </div>
            <div className="box">
                <h3 className='text-9'>Solidez e Lifetime Value</h3>
                <p className='text-10'>Sabemos que a chave para o crescimento <br />
                sustentável reside em estabelecer parcerias <br />
                sólidas e em investir no valor a longo prazo <br />
                que cada cliente representa. Ao garantir acordos <br />
                duradouros, as empresas da GoCloud criam um <br />
                ambiente propício para o desenvolvimento <br />
                contínuo, a inovação e a confiança mútua.</p>
            </div>
        </div>
    );
};

export default Section5;
