import React from 'react';
import '../css/section4.css';
// import div3 from './../imagens/div3.svg';

const Section3 = () => {
    return (
        <div className="section4">

            <div className="bottom-left">
                {/* <img src={div3} alt="Div3" /> */}
            </div>
            <div className="bottom-right">
                <h3 className='text-7'>Liderando a Revolução Tecnológica</h3>
                <p className='text-8'>Não nos contentamos com o status quo. Na GoCloud, somos <br />
                    incansáveis em nossa busca por inovação e progresso. Acreditamos <br />
                    no potencial de cada empresa em nosso ecossistema para mudar o <br />
                    mundo. Junte-se a nós e faça parte da vanguarda da tecnologia.</p>
            </div>

        </div>
    );
};

export default Section3;

