import React from 'react';
import '../css/section1.css';


const Section1 = () => {
    return (
        <div className="section1">
            <div className='sub-div'>

                <h1 className='text-1'>Conectando Investidores às <br />Oportunidades do Futuro</h1>
                <p className='text-2'>Conectamos investidores apaixonados às empresas revolucionárias que
                    <br />estão moldando o futuro.</p>
            </div>

        </div>
    );
};

export default Section1;