import React from 'react';
import '../css/form.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../css/phoneinput.css'
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import classNames from 'classnames';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Form = () => {
    const [loading, setLoading] = React.useState(false); 
    const { handleSubmit, control, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        setLoading(true);

        try {

            let emailSend = null
            let title = null

            if (data.department == 'Comercial') {
                emailSend = 'davidev.mendes@gmail.com'
                title = 'titulo Comercial'
            }

            if (data.department == 'Financeiro') {
                emailSend = 'davidev.mendes@gmail.com'
                title = 'titulo Financeiro'
            }

            if (data.department == 'Marketing') {
                emailSend = 'davidev.mendes@gmail.com'
                title = 'titulo Marketing'
            }

            if (data.department == 'Ouvidoria') {
                emailSend = 'davidev.mendes@gmail.com'
                title = 'titulo Ouvidoria'
            }

            if (data.department == 'Suporte') {
                emailSend = 'davidev.mendes@gmail.com'
                title = 'titulo Suporte'
            }


            const dados = {
                title,
                emailSend,
                ...data
            }
            console.log(dados);

            // Enviar os dados do formulário para o servidor
            await axios.post('https://gocloud.digital/email.php', dados);
            setLoading(false);
            toast.success("Sucesso!", {
                position: toast.POSITION.TOP_CENTER
              });
        } catch (error) {
            setLoading(false);
            toast.error("Erro ao enviar !", {
                position: toast.POSITION.TOP_LEFT
              });
        }
    };

    return (



        <div className='form'>
            <h3 className='text-13'>Entre em contato</h3>
            <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
                <p className='text-14'>Nome completo</p>
                <Controller
                    name="name"
                    control={control}
                    rules={{ required: 'O campo Nome completo é obrigatório.' }}
                    render={({ field }) => (
                        <input type="text" {...field} className={classNames({ 'valid': !errors.name && field.value?.length, 'invalid': errors.name })} />
                    )}
                />

                <p className='text-14'>Telefone</p>
                <Controller
                    name="phone"
                    control={control}
                    rules={{ required: 'O campo Telefone é obrigatório.' }}
                    render={({ field }) => (
                        <PhoneInput
                            country={'br'}
                            value={field.value}
                            onChange={field.onChange}
                            className={classNames({ 'phone-input-valid' : !errors.phone && field.value?.length>=13, 'phone-input-invalid': errors.phone })}
                        />
                    )}
                />

                <p className='text-14'>E-mail</p>
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        required: 'O campo E-mail é obrigatório.',
                        pattern: {
                            value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                            message: 'E-mail inválido.',
                        },
                    }}
                    render={({ field }) => (
                        <input type="email" {...field} className={classNames({ 'valid': !errors.email && field.value?.length, 'invalid': errors.email })} />
                    )}
                />
               

                <p className='text-14'>Escolha seu setor</p>
                <Controller
                    name="department"
                    control={control}
                    rules={{ required: 'Escolha um setor.' }}
                    render={({ field }) => (
                        <select {...field} className={classNames({ 'valid': !errors.department && field.value?.length, 'invalid': errors.department })}>
                            <option selected value="" disabled>Escolha um setor</option>
                            <option value="Comercial">Comercial</option>
                            <option value="Financeiro">Financeiro</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Ouvidoria">Ouvidoria</option>
                            <option value="Suporte">Suporte</option>
                        </select>
                    )}
                />

                

                <p className='text-14'>Mensagem</p>
                <Controller
                    name="message"
                    control={control}
                    rules={{ required: 'A mensagem é obrigatória.' }}
                    render={({ field }) => (
                        <textarea
                        className={classNames('msg', { 'valid': !errors.message && field.value?.length, 'invalid': errors.message })}
                            {...field}
                        ></textarea>
                    )}
                />

                <button disabled={loading} className='btn' type="submit">Enviar</button>
            </form>
            <ToastContainer />

        </div>
    );
};

export default Form;

