import React from 'react';
import '../css/footer.css'
import logo from '../imagens/footer.svg';



const Footer = () => {
    return (
        <footer className='ftr'>
            <div className=''>
                <img src={logo} alt="Logo" />
            </div>
            <div className='ftr-x'>
                <p>2023 - ® Todos os direitos redervados</p>
            </div>

        </footer>
    );
};

export default Footer;
