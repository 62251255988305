import React from 'react';
import '../css/section6.css';


const Section6 = () => {
  return (
    <div className="section6">
      <div className="left">
        <h2 className='text-11'>Seja um Protagonista da <br />
        Próxima Revolução Tecnológica</h2>
        <p className='text-12'>Junte-se a nós, esteja pronto para descobrir o novo, desafiar as <br />
        convenções e criar um impacto real. O futuro está esperando por <br />
        você na GoCloud!</p>
      </div>
      <div className="right">
        
      </div>
    </div>
  );
};

export default Section6;
