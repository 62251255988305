import React from 'react';
import logo from '../imagens/logo.svg';
import './../css/nav.css';
import { Link } from 'react-scroll';

const Nav = () => {
  return (
    <nav>
      <div className="nav-left">
        <img src={logo} alt="Logo" />
      </div>
      <div className="nav-right">
        <ul className="menu">
          <li className="menu-item"><a href="https://www.winov.com.br/sobre-a-winov/">Winov</a></li>
          <li className="menu-item">
            <Link
              to="form"
              spy={true}
              smooth={true}
              duration={500}
            >
              Contato
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
