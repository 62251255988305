import React from 'react';
import '../css/section2.css';


const Section2 = () => {
  return (
    <div className="section2">
      <h2 className='text-3'>Somos a GoCloud</h2>
      <p className='text-4'>A força-motriz por trás do futuro, que conecta investidores apaixonados às empresas mais <br />
        promissoras do setor de tecnologia. Junte-se a nós e seja parte da revolução tecnológica!</p>
    </div>
  );
};

export default Section2;
