import React from 'react';
import '../css/section3.css';
// import div2 from './../imagens/div2.svg';



const Section3 = () => {
    return (
        <div className="section3">
            <div className="top-left">
                <h3 className='text-5'>Alavancando o Crescimento do <br />Ecossistema Tecnológico</h3>
                <p className='text-6' >Somos uma holding do setor de tecnologia, dedicada a impulsionar <br />
                    o crescimento de um grupo diversificado de empresas inovadoras. <br />
                    Nossa missão é criar um ecossistema em constante expansão e <br />
                    inovação, desafiando os limites do possível.</p>
            </div>
            <div className="top-right">
                {/* <img src={div2} alt="Div2" /> */}
            </div>
        </div>
    );
};

export default Section3;
